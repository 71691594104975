:root {
  /* Light theme variables */
  --color-bg-base: #ffffff;
  --color-text: #000000;
  --color-hover-bg: hwb(0 96% 4%);
  --color-error-bg: #fff1f0;
  --color-primary: #7a40ea;
  --color-error-text: #820014;
  --color-row-bg: #ffffff;
  --color-bg-layout: #f5f5f5;
  --color-bg-container-disabled: #0000000a;
}

[data-theme='dark'] {
  /* Dark theme variables */
  --color-bg-base: #000000;
  --color-text: #ffffffd9;
  --color-hover-bg: #141414;
  --color-error-bg: #2a1215;
  --color-error-text: #f89f9a;
  --color-primary: #926df0;
  --color-row-bg: #141414;
  --color-bg-layout: #0000000;
  --color-bg-container-disabled: #ffffff1a;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('./assets/fonts/Inter/inter.css');

@media print {
  body,
  html {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: visible !important;
  }
}

.ant-modal {
  top: 0;
  padding-top: 96px;
  padding-bottom: 96px;
  max-height: 100vh;
}

.ant-modal-body {
  max-height: calc(100vh - 311px);
  overflow-y: auto;
}

.ant-modal-body::-webkit-scrollbar {
  width: 8px;
  color: var(--color-bg-base);
}

.ant-modal-body::-webkit-scrollbar-track {
  background-color: var(--color-bg-base);
  border-radius: 10px;
}

.ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
}

.ant-form-item .ant-form-item-label > label {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

textarea.ant-input,
.ant-input {
  max-width: 660px;
}

.ant-dropdown-menu::-webkit-scrollbar {
  width: 8px;
  color: var(--color-bg-base);
}

.ant-dropdown-menu::-webkit-scrollbar-track {
  background-color: var(--color-bg-base);
  border-radius: 10px;
}

.ant-dropdown-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
}

.ant-breadcrumb .ant-dropdown-trigger {
  border-radius: 4px;
  border: 1px solid var(--color-text, rgba(0, 0, 0, 0.15));
  background: var(--color-bg-container-disabled, #fff);
  margin-left: 2px;
  margin-right: 2px;
}

.ant-modal-content {
  max-height: calc(100vh - 192px);
}

.ant-select .ant-select-selector {
  color: inherit;
}
